<template>
  <b-card>
    <load-profile></load-profile>
    <div v-if="isUpdate">
      <b-card-title title-tag="h2" class="font-weight-bold mb-1">
        {{ $t("breadcrumb.updateProfile") }}
      </b-card-title>

      <validation-observer ref="updateProfileForm" #default="{ invalid }">
        <n-form-confirmation
          key="updateProfileForm"
          @submit="validateForm"
          :form="$refs.updateProfileForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data"> </n-input>

          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                :loading="loading"
                :disabled="invalid"
              >
                {{ $t("button.save") }}
              </n-button-loading>
              <b-button
                variant="outline-secondary"
                class="ml-1 mt-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="toggleUpdate"
              >
                {{ $t("button.back") }}
              </b-button>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </div>
    <div v-else>
      <b-card-title title-tag="h2" class="font-weight-bold mb-1">
        {{ $t("field.profile") }}
      </b-card-title>

      <b-row class="mt-2">
        <b-col cols="auto" class="text-center">
          <h5 class="mt-1 mb-0 text-capitalize profile-name">
            {{ data.name }}
          </h5>
          <small>{{ data.roleNames.join(", ") }}</small>
        </b-col>
      </b-row>

      <p class="mt-2">
        <feather-icon icon="CalendarIcon" />
        {{ $t("field.joint") }}
        {{ data.createdAt | formatDate("LL") }}
      </p>
      <p v-if="data.email">
        <feather-icon icon="MailIcon" /> {{ data.email }}
      </p>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="toggleUpdate"
          >
            <feather-icon icon="EditIcon" /> {{ $t("button.update") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BAlert,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./updateProfileFormInput";
import { avatarText } from "@core/utils/filter";

const ProfileRepository = Repository.get("profile");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BAvatar,
    BAlert,
    BImg,
    NButtonLoading,
    NFormConfirmation,
    NInput,
  },
  directives: {
    Ripple,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile?.username
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
    isVerify: function () {
      if (this.$store.state.profile.isVerify) {
        return this.$store.state.profile.isVerify;
      }

      return this.userData.isVerify;
    },
  },
  data() {
    return {
      data: JSON.parse(localStorage.getItem("userData")),
      isEmailChanged: false,
      loading: false,
      isUpdate: this.$route.query.isUpdate ? true : false,
      file: null,

      avatarText,
    };
  },
  methods: {
    onImageChange(file) {
      this.file = file;
    },
    toggleUpdate() {
      this.isUpdate = !this.isUpdate;
      if (this.isUpdate) {
        this.$router.replace({ query: { isUpdate: 1 } });
      } else {
        this.$router.replace({ query: {} });
      }
    },
    validateForm() {
      this.$refs.updateProfileForm.validate().then((success) => {
        if (success) {
          this.submit();
        }
      });
    },
    async submit() {
      this.loading = true;
      ProfileRepository.updateProfile({
        mediaId: this.data.mediaId,
        ...this.data,
      })
        .then((response) => {
          const user = response.data.data;
          this.data = {
            ...user,
          };
          localStorage.setItem("userData", JSON.stringify(user));
          this.$store.commit("profile/UPDATE_PROFILE", user);
          this.isEmailChanged = user.isEmailChanged;
          this.toggleUpdate();
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.$refs.updateProfileForm.setErrors(
              error.response?.data?.message
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;
    return {
      fields,
    };
  },
};
</script>
